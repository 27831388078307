<template>
    <list-search v-bind="{ ...$attrs, showsSearch, loading }" @search="emit">
        <v-row class="ma-n2">
            <v-col cols="6" lg="3" class="pa-2">
                <v-text-field label="기간검색(시작일)" v-model="query['searchDateValue'][0]" type="date" max="2999-12-31" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="6" lg="3" class="pa-2">
                <v-text-field label="기간검색(종료일)" v-model="query['searchDateValue'][1]" type="date" max="2999-12-31" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" lg="3" class="pa-2">
                <v-select v-model="query['searchKey']" :items="searchKeys" label="검색대상" placeholder="전체" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" lg="3" class="pa-2">
                <v-text-field v-model="query['searchValue']" label="검색어" clearable v-bind="attrs_input" @keydown.enter="emit" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["searchDateValue"]: [...(query["searchDateValue"] ?? [null, null])],

    ["searchKey"]: query?.["searchKey"] ?? null,
    ["searchValue"]: query?.["searchValue"] ?? null,
});

export default {
    components: {
        ListSearch,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },

        searchKeys: {
            type: Array,
            default: () => [
                { text: "제목", value: "subject" },
                { text: "내용", value: "content" },
                { text: "작성자", value: "nickname" },
            ],
        },
    },
    data: () => ({
        query: initQuery(),

        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query || {};

            delete query.page;

            if (!query["searchDateValue"][0] && !query["searchDateValue"][1]) delete query["searchDateValue"];

            if (!query?.["searchKey"]) delete query["searchKey"];
            if (!query?.["searchValue"]) delete query["searchValue"];

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
