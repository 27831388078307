<template>
    <v-icon
        v-bind="{
            color,
            title,
        }"
    >
        {{ icon }}
    </v-icon>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, default: null },
    },
    computed: {
        icon() {
            switch (this.value) {
                case true: {
                    return "mdi-checkbox-marked";
                }
                case false: {
                    return "mdi-alert-box";
                }
                case null: {
                    return "mdi-checkbox-blank";
                }
            }
        },
        color() {
            switch (this.value) {
                case true: {
                    return "primary";
                }
                case false: {
                    return "error";
                }
                case null: {
                    return undefined;
                }
            }
        },
        title() {
            switch (this.value) {
                case true: {
                    return "처리할 수 있는 데이터입니다";
                }
                case false: {
                    return "회원을 특정할 수 없습니다";
                }
                case null: {
                    return "유효성 검사가 필요합니다";
                }
            }
        },
    },
};
</script>

<style></style>
