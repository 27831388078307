var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-icon', _vm._b({}, 'v-icon', {
    color: _vm.color,
    title: _vm.title
  }, false), [_vm._v(" " + _vm._s(_vm.icon) + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }