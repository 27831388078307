<template>
    <v-layout justify-center>
        <v-responsive max-width="1080" width="100%">
            <list-heading v-model="showsSearch" title="포인트 내역" shows-filter-button>
                <template #buttons>
                    <user-point-conf>
                        <template #activator="{ attrs, on }">
                            <v-btn rounded color="secondary" class="my-n1" v-bind="attrs" v-on="on">
                                <v-icon small class="mr-2"> mdi-cog </v-icon>
                                <span> 설정 </span>
                            </v-btn>
                        </template>
                    </user-point-conf>
                </template>
            </list-heading>

            <list-search-default v-bind="{ showsSearch, loading, searchKeys }" />

            <v-row class="ma-n2" align="center">
                <v-col class="pa-2" cols="auto">
                    <v-card outlined v-bind="{ loading }">
                        <user-point-form @input="updateItem">
                            <template #activator="{ attrs, on }">
                                <v-btn text color="primary" class="caption" v-bind="{ ...attrs, loading }" v-on="on">
                                    <v-icon small class="mr-2"> mdi-hand-coin-outline </v-icon>
                                    <span> 단건 생성 </span>
                                </v-btn>
                            </template>
                        </user-point-form>
                    </v-card>
                </v-col>
                <v-col class="pa-2" cols="auto">
                    <v-card outlined v-bind="{ loading }">
                        <user-point-bulk v-on="{ search }">
                            <template #activator="{ attrs, on }">
                                <v-btn text color="primary" class="caption" v-bind="{ ...attrs, loading }" v-on="on">
                                    <v-icon small class="mr-2"> mdi-microsoft-excel </v-icon>
                                    <span> 엑셀 생성 </span>
                                </v-btn>
                            </template>
                        </user-point-bulk>
                    </v-card>
                </v-col>

                <v-spacer />

                <v-col class="pa-2" cols="auto">
                    <v-card outlined class="px-4 py-2 caption" v-bind="{ loading }">
                        <span>검색 결과: </span>
                        <b>{{ (summary?.totalCount || 0)?.format() }}</b> 건
                    </v-card>
                </v-col>

                <v-col class="pa-2" cols="auto">
                    <v-card outlined class="px-4 py-2 caption" v-bind="{ loading }">
                        <span>포인트 지급 금액: </span>
                        <b>{{ (summary?.totalPoint || 0)?.format() }}</b> 원
                    </v-card>
                </v-col>

                <v-col class="pa-2" cols="12">
                    <v-data-table v-bind="{ items, headers }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1">
                        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                        <template #footer>
                            <v-divider />
                            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { initDataTableHeaders } from "@/assets/variables/inits";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import ListSearchDefault from "@/components/console/dumb/list-search-default.vue";

import UserPointBulk from "@/components/console/user/point/user-point-bulk.vue";
import UserPointConf from "@/components/console/user/point/user-point-conf.vue";
import UserPointForm from "@/components/console/user/point/user-point-form.vue";

const headers = initDataTableHeaders([
    { value: "index", text: "#", width: +80, align: "end" },
    { value: "user.username", text: "이름", width: 100 },
    { value: "user.phone", text: "연락처", width: 120, formatterType: "phone" },
    { value: "amount", text: "포인트 금액", width: 100, align: "end", formatterType: "number", suffix: "원" },
    { value: "remain", text: "남은 금액", width: 100, align: "end", formatterType: "number", suffix: "원" },
    { value: "remark", text: "비고" },
    { value: "createdAt", text: "일자", width: 160, formatterType: "datetime" },
]);

const searchKeys = [
    { text: "이름", value: "user.username" },
    { text: "연락처", value: "user.phone" },
];

export default {
    components: {
        ListHeading,
        ListSearchDefault,

        UserPointBulk,
        UserPointConf,
        UserPointForm,
    },
    data: () => ({
        points: [],
        summary: {
            totalCount: 0,
            totalPoint: 0,
        },

        limit: 20,
        loading: false,
        showsSearch: true,

        headers,
        searchKeys,
    }),
    computed: {
        items() {
            return this.points.map((item, index) => ({ ...item, index: this.summary.totalCount - index - (this.page - 1) * this.limit }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { query } = this.$route;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        page() {
            this.search();
        },
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            await this.search();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                let { summary, points } = await api.console.users.points.gets({
                    headers: { skip, limit },
                    params,
                });

                this.points = points;
                this.summary = { ...this.summary, ...summary };
            } finally {
                this.loading = false;
            }
        },
        updateItem(item) {
            const index = this.points.findIndex(({ _id }) => _id == item._id);
            if (0 <= index) this.points.splice(index, 1, item);
            else {
                this.points = [item, ...this.points];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .word-break-keep-all {
        word-break: keep-all;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
