<template>
    <v-dialog v-model="shows" width="320" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> 포인트 설정 <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-divider />
            <v-card-text class="py-5">
                <v-row class="ma-n2">
                    <v-col cols="12" class="pa-2">
                        <view-section-card title="연간사용제한">
                            <template #actions> <v-switch v-model="form.point.useLimitYearly.enabled" v-bind="attrs_switch" /> </template>
                            <v-card :disabled="!form.point.useLimitYearly.enabled" flat>
                                <v-card-text>
                                    <v-row class="ma-n2">
                                        <v-col cols="12" class="pa-2">
                                            <v-text-field v-model="form.point.useLimitYearly.value" label="금액" type="number" v-bind="attrs_input" />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </view-section-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn text color="primary" v-bind="{ loading }" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, attrs_switch, initCommonSetting } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

export default {
    components: {
        ViewSectionCard,
    },
    data: () => ({
        form: initCommonSetting(),

        shows: false,
        loading: false,

        attrs_input,
        attrs_switch,
    }),
    watch: {
        shows(shows) {
            if (shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { setting } = await api.console.setting.get();

                this.form = initCommonSetting(setting);
            } finally {
                if (this.loading) this.loading = false;
            }
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { _id, point } = this.form;

                let form = { _id, point };
                await api.console.setting.put(form);

                alert("저장되었습니다");
                this.loading = false;
                this.shows = false;
            } finally {
                if (this.loading) this.loading = false;
            }
        },
    },
};
</script>
