<template>
    <v-icon
        v-bind="{
            color,
            title,
        }"
    >
        {{ icon }}
    </v-icon>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, default: null },
    },
    computed: {
        icon() {
            switch (this.value) {
                case true: {
                    return "mdi-checkbox-marked";
                }
                case false: {
                    return "mdi-alert-box";
                }
                case null: {
                    return "mdi-checkbox-blank";
                }
            }
        },
        color() {
            switch (this.value) {
                case true: {
                    return "primary";
                }
                case false: {
                    return "error";
                }
                case null: {
                    return undefined;
                }
            }
        },
        title() {
            switch (this.value) {
                case true: {
                    return "성공적으로 생성하였습니다";
                }
                case false: {
                    return "생성에 실패하였습니다";
                }
                case null: {
                    return undefined;
                }
            }
        },
    },
};
</script>

<style></style>
