<template>
    <v-dialog v-model="shows" width="720" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> 포인트 엑셀 생성 <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-divider />
            <v-card-text class="py-5">
                <v-row class="ma-n2">
                    <v-col cols="12" class="pa-2">
                        <v-row class="ma-n2">
                            <v-col cols="" class="pa-2">
                                <v-file-input v-model="file" v-bind="attrs_input" label="엑셀파일" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" prepend-icon="mdi-microsoft-excel" />
                            </v-col>
                            <v-col cols="1" class="pa-2">
                                <v-btn outlined icon color="green" title="엑셀양식 다운로드" @click="down">
                                    <v-icon>mdi-microsoft-excel</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="pa-2">
                        <v-data-table v-bind="{ items, headers, loading }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1" height="480">
                            <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                            <template #[`item.isOkay`]="{ value }">
                                <bulk-okay v-bind="{ value }" />
                            </template>
                            <template #[`item.isDone`]="{ value }">
                                <bulk-done v-bind="{ value }" />
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-col cols="auto" class="py-0">
                    <span class="caption">
                        엑셀 항목 수: <b> {{ total.format() }} </b> 건
                    </span>
                </v-col>
                <v-col cols="auto" class="py-0">
                    <span class="caption">
                        체크 성공 수: <b> {{ count_okay.format() }} </b> 건
                    </span>
                </v-col>
                <v-col cols="auto" class="py-0">
                    <span class="caption">
                        생성 성공 수: <b> {{ count_done.format() }} </b> 건
                    </span>
                </v-col>
                <v-spacer />
                <v-btn
                    v-bind="{
                        text: true,
                        color: 'primary',
                        loading,
                        disabled: disabled_validate,
                    }"
                    @click="okay"
                >
                    <span> 체크 </span>
                </v-btn>
                <v-btn
                    v-bind="{
                        text: true,
                        color: 'primary',
                        loading,
                        disabled: disabled_save,
                    }"
                    @click="save"
                >
                    <span> 생성 </span>
                </v-btn>
            </v-card-actions>
            <v-fade-transition>
                <v-overlay v-show="showsOverlay" absolute color="white">
                    <div style="width: 240px">
                        <v-progress-linear
                            v-bind="{
                                value: (count / total) * 100,
                                height: 20,
                                rounded: true,
                            }"
                        />
                    </div>
                </v-overlay>
            </v-fade-transition>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import { attrs_input, initDataTableHeaders } from "@/assets/variables";

import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import BulkOkay from "./bulk/bulk-okay.vue";
import BulkDone from "./bulk/bulk-done.vue";

const headers = initDataTableHeaders([
    { value: "index", text: "#", width: +60, align: "end" },
    { value: "user.username", text: "이름", width: 100 },
    { value: "user.phone", text: "연락처", width: 120, formatterType: "phone" },
    { value: "amount", text: "포인트", width: 100, align: "end", formatterType: "number", suffix: "원" },
    { value: "remark", text: "비고" },
    { value: "isOkay", text: "체크", width: +56, align: "center", class: "pa-0" },
    { value: "isDone", text: "생성", width: +56, align: "center", class: "pa-0" },
]);

export default {
    components: {
        AutocompleteUser,
        BulkOkay,
        BulkDone,
    },
    data: () => ({
        file: null,
        items: [],

        mode: "file",

        loading: false,

        shows: false,
        showsOverlay: false,

        headers,
        attrs_input,
    }),
    computed: {
        total() {
            return this.items.length;
        },
        count_okay() {
            return this.items.filter(({ isOkay }) => isOkay).length;
        },
        count_done() {
            return this.items.filter(({ isDone }) => isDone).length;
        },
        count() {
            switch (this.mode) {
                case "okay": {
                    return this.count_okay;
                }

                case "save": {
                    return this.count_done;
                }

                default: {
                    return 0;
                }
            }
        },
        disabled_validate() {
            return !this.file || !this.total || this.count_okay == this.total;
        },
        disabled_save() {
            return !this.file || !this.total || this.count_okay != this.total || !this.items.some(({ isDone }) => isDone === null);
        },
    },
    watch: {
        shows(shows) {
            if (shows) this.init();
            else {
                if (this.mode !== "save") return;
                if (this.count_done < 1) return;
                this.$emit("search");
            }
        },
        file() {
            this.gets();
        },
    },
    methods: {
        init() {
            this.mode = "file";
            this.file = null;
            this.items = [];
        },

        async gets() {
            if (!(this.file instanceof File)) {
                this.init();
                return;
            }

            if (this.loading) return;
            else this.loading = true;

            let reader = new FileReader();
            reader.onload = async () => {
                try {
                    let workBook = XLSX.read(reader.result, { type: "binary" });
                    let workSheets = workBook.SheetNames.map((sheetName) => XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]));

                    this.items = workSheets[0].map((item, index) => ({
                        index: index + 1,
                        user: {
                            username: item["이름"],
                            phone: item["연락처"].replaceAll(/\D/g, ""),
                        },
                        amount: +item["포인트"],
                        remark: item["비고"],
                        isOkay: null,
                        isDone: null,
                    }));

                    this.mode = "okay";
                } catch (error) {
                    console.error(error);
                    alert("데이터를 파싱하던 중 오류가 발생하였습니다. 파일 양식을 확인해주세요.");
                } finally {
                    this.loading = false;
                }
            };
            reader.readAsBinaryString(this.file);
        },

        async down() {
            let workbook = new XLSX.utils.book_new();
            let rows = [{}].map(() => ({
                ["이름"]: "홍길동",
                ["연락처"]: "010-1234-5678",
                ["포인트"]: "1000",
                ["비고"]: "수동적립 테스트",
            }));
            let worksheet = XLSX.utils.json_to_sheet(rows);
            XLSX.utils.book_append_sheet(workbook, worksheet, "포인트 엑셀생성");
            XLSX.writeFile(workbook, "포인트_액셀생성_양식.xlsx");
        },

        async okay() {
            if (this.loading) return;
            else this.loading = true;

            this.showsOverlay = true;

            try {
                this.items = await Promise.all(
                    this.items.map(async (item, index) => {
                        let { username, phone } = item?.user || {};

                        let user = (
                            await api.console.users.gets({
                                params: { username, phone },
                            })
                        )?.users?.[0];

                        if (user) {
                            item.user = user;
                            item._user = user._id;
                        }

                        item.isOkay = !!user;

                        this.items[index] = item;

                        return item;
                    })
                );

                setTimeout(() => (this.mode = "save"), 500);
            } finally {
                if (this.loading) this.loading = false;
                if (this.showsOverlay) this.showsOverlay = false;
            }
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;

            this.showsOverlay = true;

            try {
                for (let [index, item] of this.items.entries()) {
                    let form = { ...item };

                    delete form.user;
                    delete form.isOkay;
                    delete form.isDone;

                    try {
                        await api.console.users.points.post(form);
                        item.isDone = true;
                    } catch {
                        item.isDone = false;
                    } finally {
                        this.items[index] = item;
                    }
                }

                alert("생성이 완료되었습니다");
            } finally {
                if (this.loading) this.loading = false;
                if (this.showsOverlay) this.showsOverlay = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .word-break-keep-all {
        word-break: keep-all;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }

    tbody tr:last-child td {
        border-bottom: thin solid rgba(0, 0, 0, 0.12);
        margin-bottom: -1px;
    }
}
</style>
