<template>
    <v-dialog v-model="shows" width="320" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> 포인트 단건 생성 <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-divider />
            <v-card-text class="py-5">
                <v-row class="ma-n2">
                    <v-col cols="12" class="pa-2">
                        <autocomplete-user v-model="form._user" label="회원" v-bind="attrs_user" />
                    </v-col>
                    <v-col cols="12" class="pa-2">
                        <v-text-field v-model="form.amount" label="금액" type="number" v-bind="attrs_input" />
                    </v-col>
                    <v-col cols="12" class="pa-2">
                        <v-text-field v-model="form.remark" label="비고" v-bind="attrs_input" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn text color="primary" v-bind="{ loading }" @click="save">생성</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, initUserPoint } from "@/assets/variables";

import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

let attrs_user = {
    ...attrs_input,
    sort: { username: 1, phone: 1 },
};

export default {
    components: {
        AutocompleteUser,
    },
    data: () => ({
        form: initUserPoint(),

        shows: false,
        loading: false,

        attrs_input,
        attrs_user,
    }),
    watch: {
        shows(shows) {
            if (shows) this.init();
        },
    },
    methods: {
        init() {
            this.form = initUserPoint();
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { ...form } = this.form;

                delete form.createdAt;
                delete form.remain;
                delete form._order;
                delete form._participant;

                let { point } = await api.console.users.points.post(form);

                alert("생성되었습니다");
                this.$emit("input", point);
                this.loading = false;
                this.shows = false;
            } finally {
                if (this.loading) this.loading = false;
            }
        },
    },
};
</script>
